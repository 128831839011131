import { LOCALES } from "../locales";

export const en = {
  [LOCALES.ENGLISH]: {
    "418code": "The HTTP <teapot>418 I'm a teapot</teapot> client error response code indicates that the server refuses to brew coffee because it is, permanently, a teapot.",
    "7tech": "7 technologies",
    "about": "Project",
    "about.figma": "Implement semi-randomly generated <figma>Figma design</figma>",
    "about.routes": "React routes with auth wrapper component",
    "about.httponly": "User registration, login, and http-only cookies auth",
    "about.profile": "Editable user name, email, and language (react-intl)",
    "about.valid" :"Custom form validation with a custom hook",
    "about.data": "JSON card data wrangling",
    "about.search": "Search through movie cards with a short movie modifier",
    "about.preview": "Youtube trailer preview on card click",
    "about.loader": "Loader displaying next N movie cards",
    "about.savecards": "Save movie cards to my backend",
    "about.localstorage": "Persistant app state with localStorage",
    "about.domain": "Custom domain, GCP debian VM with Nginx, SSL certificate",
    "about.bem": "<bem>BEM</bem> naming CSS style system",
    "about.node": "Node / Express: middlewares, routers, controllers, REST api, error handling",
    "about.mongo": "MongoDB / Mongoose: schemas, models, CRUD operations, refs, statics",
    "about.security": "Security: bcrypt password hashing, Celebrate / Joi + MongoDB validation with regex matching, JWT token, http-only cookies, rate limiter, winston loggers, .env production variables, pinned npm package versions",
    "about.domain_back": "Google Compute Platform: custom domain, debian VM, Nginx with proxy pass, PM2 with auto reload, SSL certificate, dynamic DNS",
    "about_me": "I got inspired to become a web developer while testing <sdc>Google's self-driving cars</sdc> as a contractor in Silicon Valley. Taking a <chm>tour of Computer History museum</chm>, and going to various conferences also added to my motivation. I'm originally from Russia and one can say that <yndx>Yandex</yndx> is Google's Russian cousin. That's why I decided to study web development with <ypen>Yandex Practicum</ypen> (<ypru>Russian branch</ypru>) - their educational startup. I like to write code using Oculus Quest 2 VR headset, nature, meditation, music and improv comedy. I got my Bachelor's in Business Administration from University of Connecticut.",
    "account": "Account",
    "alrdy_registered": "Already registered?",
    "app_title": "Movies explorer",
    "back": "Back",
    "blank": "blank",
    "edit": "Edit",
    "email_placeholder": "explorer@movies.com",
    "enter": "Login",
    "error_msg": "An error happened!",
    "email_error": "Please enter a valid email address.",
    "pwd_error": "Please enter a valid password",
    "name_error": "Please enter a valid name.",
    "exit_account": "Logout",
    "fe_dev": "Front end developer",
    "film": "Movie",
    "glad_to_see": "Welcome back!",
    "havent_registered": "Haven't registered yet?",
    "hi": "Hi ",
    "home_page": "Home page",
    "lang": "en",
    "locale": "Language",
    "load_more" : "Show more",
    "logo": "logo",
    "mobile_menu_btn_aria": "Menu button",
    "movies": "Movies",
    "movie_preview_pic": "movie preview image",
    "my_name": "Maxim",
    "name": "Name",
    "noscript": "You need to enable JavaScript to run this app.",
    "notfound": "Page not found",
    "nothing_found": "Nothing is found",
    "password": "Password",
    "placeholder_name": "John",
    "popup_close_btn": "Popup close button",
    "popup_err_msg" : "Error!",
    "popup_icon": "popup icon",
    "portfolio": "Portfolio",
    "profile_success_msg": "Profile successfully updated!",
    "proj_goal": "Project goal",
    "proj_goal_desc": "Make a search through a list of 100 movies from <beat>beatfilm festival dataset public api</beat> and present the result as clickable cards that lead to youtube previews with ability to save and search saved cards for each registered user.",
    "proj_steps": "Project steps",
    "proj_steps_desc": "Make a trello plan, implement backend, implement design, implement functionality and final touches.",
    "register": "Register",
    "registration": "Registration",
    "responsive": "Responsive site",
    "saved_movies": "Saved movies",
    "save_btn_aria": "Movie save button",
    "search_btn_aria": "Search button",
    "search_switch_aria": "Short movie switch",
    "shorts": "Shorts",
    "spa": "Single page application",
    "static": "Static site",
    "student": "Student",
    "study_project": "School of web development student project.",
    "study_project_beat": "Student project Yandex.Practicum х BeatFilm.",
    "tech": "Technologies",
    "tech_description": "The diploma project is built with following technologies",
    "welcome": "Welcome!",
    "yndx.practicum": "Yandex.Practicum"
  }
};